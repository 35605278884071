import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

//import hhh from '../../../images/hhhhhhh.png'

const tehnilisedAndmed2 = () => (
  <Layout>
    <SEO title="Siirdeseinte tehnilised andmed" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>TEHNILISED ANDMED</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link to="/ruumijagajad/voldikuksed/">
          <div className="epo">
            <h2>Voldikuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/lootsuksed/">
          <div className="epo">
            <h2>Lõõtsuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/siirdeseinad/">
          <div className="epo">
            <h2>Siirdeseinad</h2>
          </div>
        </Link>
        <Link
          to="/ruumijagajad/voldikseinad/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
          partiallyActive={true}
        >
          <div className="epo">
            <h2>Voldikseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/referentsid/">
          <div className="epo">
            <h2>Referentsid</h2>
          </div>
        </Link>
      </div>

      <div className="ta-wrapper">
        <h3>Mõõdud ja kaalud</h3>
        <div className="ta-text">
          <b>Paksus:</b>
          <p>80 mm, kõik seinatüübid</p>

          <b>Kaal/m²:</b>
          <p>26-42 kg sõltuvalt helipidavusest</p>

          <b>Elemendi laius:</b>
          <p>minimaalne 400 mm ja maksimaalne 1000 mm</p>

          <b>Elemendi kõrgus:</b>
          <p>maksimaalselt 6000 mm</p>

          <b>Pinnaviimistlus:</b>
          <p>vabal valikul</p>

          <b>Helipidavus:</b>
          <p>Rw 41-52 dB</p>
        </div>
      </div>
    </div>

    <div className="ta-grid">
      <p>020</p>
      <p>Keskripustusega, üheosaline,paaritu, uks paremakäeline</p>
      <a href="/static/C1-020_EST_BL.pdf">JOONIS</a>

      <p>020B</p>
      <p>Keskripustusega, üheosaline, paaritu, uks vasakukäeline</p>
      <a href="/static/C1-020B_EST_BL.pdf">JOONIS</a>

      <p>021</p>
      <p>Keskriputusega, üheosaline, paaris, uks vasakukäeline</p>
      <a href="/static/C1-021_EST_BL.pdf">JOONIS</a>

      <p>021B</p>
      <p>Keskriputusega, üheosaline, paaris, uks paremakäeline</p>
      <a href="/static/C1-021B_EST_BL.pdf">JOONIS</a>

      <p>022</p>
      <p>Keskriputusega, paaris, keskelt avanev, paarisuksega</p>
      <a href="/static/C2-022_EST_BL.pdf">JOONIS</a>

      <p>022B</p>
      <p>Keskriputusega, paaritu, keskelt avanev, uks keskel</p>
      <a href="/static/C2-022B_EST_BL.pdf">JOONIS</a>

      <p>023</p>
      <p>Keskriputusega, paaritu, keskelt avanev, paarisuksega</p>
      <a href="/static/C2-023_EST_BL.pdf">JOONIS</a>

      <p>024</p>
      <p>Servriputusega, paaritu, üheosaline, uks paremakäeline</p>
      <a href="/static/K1-024H_EST_BL.pdf">JOONIS</a>

      <p>025</p>
      <p>Servriputusega, paaritu, üheosaline, uks vasakukäeline</p>
      <a href="/static/K1-025V_EST_BL.pdf">JOONIS</a>

      <p>026</p>
      <p>Servriputusega, paaris, keskelt avanev</p>
      <a href="/static/K2-026_EST_BL.pdf">JOONIS</a>
    </div>
  </Layout>
)

export default tehnilisedAndmed2
